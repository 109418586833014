/* <!-- =============== GOOGLE FONTS =================== --> */


@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');


/* <!-- =============== VARIABLES CSS =================== --> */
:root {
  --header-height: 3rem;
  
  /* <!-- =============== COLORS =================== --> */
  
  --hue: 0;
  --sat: 0%;
  --title-color: hsl (var(--hue), var(--sat), 20%);
  --title-color: #1b1b1b;

  /* --title-color-dark: hsl (var(--hue), var(--sat), 0%); */
  --title-color-dark: #EF3340;
  --title-color-logo: ##f14b56;
  --text-color: #1b1b1b;
  --body-color: #4c4c4c;
  /* --body-color: hsl (v÷ar(--hue), var(--sat), 98%); */
  --container-color: #fff;
  --container-color-grey: #eee;
  
  /* <!-- =============== Font and TYPO =================== --> */
  --body-font: 'Work Sans', sans-serif;
  
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1.2rem;
  --small-font-size: 1rem;
  --smaller-font-size: 0.875rem;
  --tiny-font-size: 0.75rem;

/*   
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;
   */
  /* <!-- =============== Font Weight =================== --> */
  
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  
  /* <!-- =============== Bottom Margins =================== --> */

  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;

  /* <!-- =============== z index  =================== --> */


  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;

  /* <!-- =============== Responsive Typography =================== --> */

  @media screen and (max-width: 992px)  {
    :root {
      --big-font-size: 2.75rem;
      --h1-font-size: 1.55rem;
      --h2-font-size: 1.25rem;
      --h3-font-size: 1rem;
      --normal-font-size: 0.938rem;
      --small-font-size: 0.813rem;
      --smaller-font-size: 0.75rem;
    }
    
  }

}

  /* <!-- ===============  BASE=================== --> */

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

  }

  html {
    scroll-behavior: smooth;
  }


  body,
  button,
  input,
  textarea {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
  }

  body {
    background-color: var(--container-color);
    color: var(--text-color);
    width: 100%;
    height: 100%;
    overflow-x: hidden;

  }

  h1, h2, h3 {
    color: var(--title-color);
    font-weight: var(--font-semi-bold);

  }

  ul {
    list-style: none;

  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
    border: none;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* <!-- =============== REUSSABLE CSS CLASSES =================== --> */

  .section {
    padding: 5rem 0 1rem;
  }

  .section__title {
    font-size: var(--h1-font-size);
    color: var(--title-color);
    padding-top: 1.5rem;
  }


  .section__subtitle {
    display: block;
    font-size: var(--small-font-size);
    margin-bottom: 4rem;
  }

  .section__title,
  .section__subtitle {
    text-align: center;
  }

  /* <!-- =============== LAYOUT  =================== --> */

  .container {
    max-width: 968px;
    margin-left: auto;
    margin-right: auto;
  }

  .grid {
    display: grid;
    gap: 1.5rem;
  }

  .dflex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

  }


  /* <!-- =============== BUTTONS =================== --> */

  .button {
    display: inline-block;
    background-color: var(--body-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
  }

  .button:hover {
    background-color: var(--title-color-dark);
  }

  .button__icon {
    margin-left: var(--mb-0-5);
  }

  .button--flex {
    display: inline-flex;
    align-items: center;
  }

  /* <!-- =============== BREAKPOINTS  =================== --> */

  /* large */
  @media screen and (max-width: 992px) {
    .container {
      margin-left: var(--mb-1-5);
      margin-right: var(--mb-1-5);

    }

    .button {
      padding: 1rem 1.75rem;
    }

    .button__icon {
      width: 22px;
      height: 22px;
    }
    
  }
  /* medium */
  @media screen and (max-width: 768px) {

    body {
      margin: 0 0 var(--header-height) 0;
    }


    .section {
      padding: 2rem 0 4rem ;
    }

    .section__subtitle {
      margin-bottom: var(--mb-3);
    }

  }
  @media screen and (max-width: 576px) {

  }
  /* small */
  @media screen and (max-width: 350px) {
    :root {
      --big-font-size: 2.25rem;
    }
    .container {
      margin-left: var(--mb-1);
      margin-right: var(--mb-1);

    }

  

  }
  /* <!-- ===============  =================== --> */
  /* <!-- ===============  =================== --> */
  




/* 


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
