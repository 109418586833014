.image__filters {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom:  var(--mb-2);

}

.image__item {
  cursor: pointer;
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-size: var(--small-font-size);
}

.image__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);

}


.image__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.image__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.image__img {
  width: 295px;
  height: 166px;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.image__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.image__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.image__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.image__button:hover .image__button-icon {
  transform: translateX(0.25rem);
}

/* active images */
.active-image {
  background-color: var(--title-color);
  color: var(--container-color);
}



  /* large */
  @media screen and (max-width: 992px) {
    .image__container {
      gap: 1.25rem;
    }

    .image__card {
      padding: 1rem;
    }

    .image__img {
      margin-bottom: 0.75rem;
    }

    .image__title {
      margin-bottom: 0.25rem;
    }

  }


 @media screen and (max-width: 768px) {
    .image__container {
      grid-template-columns: max-content;
    }

    .image__filters {
      display: grid;
      grid-template-columns: repeat(3, 130px);
      justify-content: center;
      text-align: center;
      align-items: center;
      column-gap: 1rem;
      row-gap: 0.5rem;
    }
  }



  @media screen and (max-width: 576px) {
    .image__container {
      grid-template-columns: 1fr;
    }

    
  

    .image__img {
      width: 100%;
    }

    .image__filters {
      display: grid;
      grid-template-columns: repeat(2, 130px);
      justify-content: center;
      text-align: center;
      align-items: center;
      column-gap: 1rem;
      row-gap: 0.5rem;
    }
  

  }

  @media screen and (max-width: 350px) {
    .image__item {
      font-size: var(--small-font-size);
    }

    .image__filters {
      column-gap: 0.25rem;
    }
  }
  
