
.hero__subtitle {
  /* position: relative; */
  font-size: var(--h3-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
  margin-left: var(--mb-1);
  color: var(--container-color);
  opacity: 1;

  /* background-color: #1b1b1b;
  background-image: linear-gradient( #1b1b1b, #eee);
  background-size: 100%; */
  /* background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent; */


}

.hero__text__container {
  padding-left: 2rem;
  /* max-width: 70vw; */
width: 100%;
padding: 1rem;
position: absolute;
/* bottom : 25%; */

  /* background-color: #1b1b1b;
  background-image: linear-gradient( #1b1b1b, #eee);
  background-size: 50%;
   */
  /* background:; */
  /* background: radial-gradient(180deg, rgba(27,27,27,0.3) 0%, rgba(51,51,51,0.3) 100%); */
  /* background: rgb(27,27,27); */

  background: radial-gradient(circle, rgba(27,27,27,0.6) 0%, rgba(51,51,51,0.4) 50%, rgba(238,238,238,0.1) 100%);
 /* background-clip: text; */
  /* background-clip: content-box;
   /* opacity: 0.2; */
  /* opacity: 0.5;
  /* margin-left: 5.4rem; */
}

.hero__title {
  font-size: var(--h1-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-bold);
  margin-bottom: var(--mb-1);
  margin-left: var(--mb-1);
  color: var(--container-color);
}

.hero__paragraph {
  font-size: var(--normal-font-size);
  padding-left: 5.4rem;
  font-weight: var(--font-normal);
  margin-bottom: var(--mb-1);
  margin-left: var(--mb-1);
  color: var(--container-color-grey);
}

@media screen and (max-width: 768px) {

  .hero__container {
    
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 3rem;
  }

  .hero__text__container * {
    padding-left: 1rem;
    margin-left: 0;
  } 
}