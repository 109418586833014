.achievements__container {
  max-width: 768px;
}

.achievements__tabs {
  display: flex;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.achievements__button {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
  margin: 0 var(--mb-1);
  /* cursor: pointer; */
}

.achievements__button:hover {
  color: var(--title-color-dark);
}

.achievements__icon {
  font-size: 1.8rem;
  margin-right: var(--mb-0-25);
}
.achievements__icon__comp {
  height: 2.5rem;
  width: 2.5rem;
  margin-right: var(--mb-0-25);
}

.achievements__active {
  color: var(--title-color-dark);
}

.achievements__sections {
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;

}

.achievements__content {
  display: none;

}

.achievements__content-active {
  display: block;
}

.achievements__data {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.achievements__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.achievements__subtitle {
  display: inline-block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1);
}

.achievements__calendar {
  font-size: var(--small-font-size);

}

.achievements__rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: var(--text-color);
  border-radius: 50%;
}


.achievements__line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--text-color);
  transform: translate(6px, -7px);
}

/* <!-- =============== BREAKPOINTS  =================== --> */

  /* large */
  @media screen and (max-width: 992px) {
    .achievements__container {
      margin-left: auto;
      margin-right: auto;
    }
  }
  /* medium */
  @media screen and (max-width: 768px) {
    .achievements__container {
      margin-left: var(--mb-1-5);
      margin-right: var(--mb-1-5);
    }
   

  }
  @media screen and (max-width: 576px) {
    .achievements__sections {
      grid-template-columns:  initial;
    }

    .achievements__button {
      margin: 0 var(--mb-0-75);
    }

  }
  /* small */
  @media screen and (max-width: 350px) {
    .achievements__data {
      gap: 0.5rem;
    }
  

  }
