.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-items:start; */
  flex-flow: column;
  /* left: 10vw; */
  width: 100%;
  height: 100%;
  margin: 0;
/* padding-left: 30; */
  padding: 0;
  pointer-events: none;
  
}
