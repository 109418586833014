.scrollup {
  position: fixed;
  right: 2rem;
  bottom: -20%;
  background-color: var(--title-color);
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup__icon {
  /* font-size: 1.5rem; */
  width: 1.5rem;
  height: 1.5rem;
  color: var(--container-color);
}

.scrollup:hover {
  background-color: var(--title-color-dark);
}

/* Show Scroll */

.show-scroll {
  bottom: 3rem;
}


/* <!-- =============== BREAKPOINTS  =================== --> */

  /* large */
  @media screen and (max-width: 992px) {
    .scrollup {
      right: 1.5rem;
      padding: 0.25rem 0.4rem;
    }

    .scrollup__icon {
      font-size: 1.25rem;
    }
  }
  /* medium */
  @media screen and (max-width: 768px) {
    .show-scroll {
      bottom: 4rem;

    }

  /* small */
  @media screen and (max-width: 350px) {
    .scrollup {
      right: 1rem;
    }

  }
  }