.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--container-color);
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding-left: 1.5rem;
  padding-right: 4rem;
}

.nav img {
  height: 2.5rem;
  width: auto;
  border-radius: 0;
  padding-left: 3rem;
}

.nav__logo, 
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 3rem;

}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: .3s;

}

.nav__icon,
.nav__icon__comp,
.nav__close,
.nav__toggle {
  display: none;
}


/*  ACTIVE LINK */
/* .active-link,  */
.nav__link:hover {
  color: var(--title-color-dark);
}

/* Scroll header change background */

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

  /* <!-- =============== BREAKPOINTS  =================== --> */

  /* medium */
  @media screen and (max-width: 768px) {
    .header {
      top: 0;
      bottom: initial;
    }

    .nav {
      height: var(--header-height);
    }

    /* .nav__toggle, */
    .nav__logo {
      display: none;
    }

    .nav__menu {
      position: fixed;
      top: -100%;
      left: 0;
      width: 100%;
      background-color: var(--container-color);
      padding: 2rem 1.5rem 4rem;
      box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 0 0 1.5rem 1.5rem;
      transition: .3s;

    }

    /* show menu */
    .show-menu  {
      top: 0;  
      
    }

    .menu__icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    .nav__list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }

    .nav__icon {
      font-size: 1.5rem;
    }
    .nav__icon__comp {
      height: 1.5rem;
      width: 1.5rem;
    }

    .nav__close {
      position: absolute;
      left: 1.3rem;
      top: 1rem;
      font-size: 1.5rem;
      cursor: pointer;
      color: var(--title-color);

    }

    .nav__close:hover {
      color: var(--title-color-dark);
    }

    .nav__toggle {
      font-size: 1.1rem;
      cursor: pointer;
    }
    .nav__icon,
    .nav__icon__comp,
    .nav__close,
    .nav__toggle {
      display: block;
    }
  }

    /* medium */
    @media screen and (max-width: 930px) {
   

      .nav__list {
        column-gap: 1.5rem;
      }
    }
    /* small */
    @media screen and (max-width: 350px) {
      .nav__menu {
        padding: 2rem 0.25rem 4rem;
      }

      .nav__list {
        column-gap: 0.5rem;
      }
    }

