.footer {
  background-color: var(--container-color);
  border-top:  1px solid  var(--title-color-dark);
}

.footer__container {
  padding: 1.5rem 0 2rem;
}
.footer__link {
  color: var(--title-color);
  /* font-size: var(--normal-font-size); */

}

.footer__title{
  color: var(--title-color);
}

.footer__title {
  text-align: center;
  margin-bottom: var(--mb-2);
}

.footer__link:hover {
  color: var(--title-color-dark);

}

.footer__list {
  display: flex;
  justify-content: center;
  column-gap: 1.5rem;
  margin-bottom: var(--mb-1);

}

.footer__groups {
  padding: 2rem 2rem 2.5rem;
  margin-top: 2rem;

  display: flex;
  justify-content: space-between;

  /* grid-template-columns: repeat(3, 200px);
  gap: 0.5rem;
  margin-bottom: var(--mb-2); */
}

.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;

}

.footer__social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.footer__social-link:hover {
  background-color: var(--title-color-dark);
}

.footer__copy {
  display: block;
  margin-top: 4.5rem;
  color: var(--title-color);
  text-align: center;
  font-size: var(--smaller-font-size);

}

.address__title {
  margin-bottom: var(--mb-1);
}

.address__details {
  margin-bottom: var(--mb-0-5);
}

/* <!-- =============== BREAKPOINTS  =================== --> */

  /* large */
  @media screen and (max-width: 992px) {
    .footer__social-link {
      padding: 0.25rem;
      border-radius: 0.25rem;
      font-size: 1rem;
    }
    
  }
  /* medium */
  @media screen and (max-width: 768px) {

   

  }
  @media screen and (max-width: 576px) {

    .footer__container {
      padding: 1rem 0 1rem;
    }

    .footer__list {
      column-gap: 1rem;
      margin-bottom: var(--mb-2);
    
    }

    .footer__groups {
      display: grid;
      grid-template-columns: max-content;
      gap: 3rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 1rem 1rem 1.5rem;

    }
    .footer__link {
      font-size: var(--small-font-size);
  
    }
  }

  /* small */
  @media screen and (max-width: 350px) {
  
    .footer__list {
      column-gap: 0.5rem;

    }

  }