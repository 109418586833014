* {
  margin: 0;
  padding: 0;

}

body {
  height: 100vh;
}
.loader__container {
  
  background-color: #eee;
  height: 150px;
  width: 150px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.loader__svg__container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  /* background-color: #1b1b1b; */
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.line {
  display: inline-block;
  width: 10px;
  height: 40px;
  background-color: #1b1b1b;
  border-radius: 10px;
}

.line:nth-child(2) {
  margin: 0 5px;
  height: 70px;
}

.loader__image {
  justify-content: center;
  align-items: center;
}

.loader__img {
  height: 72px;
  width: 72px;
}

.loader__div2 {
  align-items: center;
  justify-content: center;
}
.loader__div {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  
}

.line1 {
  width: 15px;
  height: 40px;
  background-color: #1b1b1b;
  bottom: 0;
  position: absolute;

}

.line2 {
  width: 15px;
  height: 60px;
  background-color: #1b1b1b;
  margin-left: 20px;



}

